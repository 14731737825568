body,
html {
  overflow-x: scroll;
  overflow-x: hidden;
  width: 100%;
  background: #f4f9fc;
}
a:active {
  opacity: 0.8;
}
.font-h {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.img {
  display: block;
  width: 100%;
  height: 100%;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.center {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}
.centering {
  position: absolute;
  width: 1920px;
  left: 50%;
  top: 0;
  margin-left: -960px;
}
/*公共导航*/
.nav {
  position: relative;
  z-index: 9999;
}
.nav .nav-top {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 30;
  height: 79px;
  background: #000;
  font-size: 12px;
}
.nav .nav-top .logo {
  width: 129px;
  height: 37px;
  margin: 20px 85px 0 10px;
}
.nav .nav-top .logo img {
  display: block;
  width: 100%;
  height: 100%;
}
.nav .nav-top .cont {
  position: relative;
}
.nav .nav-top .cont .clearfix {
  margin-left: 360px;
}
.nav .nav-top .cont ul {
  height: 79px;
  line-height: 79px;
}
.nav .nav-top .cont li {
  float: left;
  width: 100px;
  margin-right: 20px;
  text-align: center;
}
.nav .nav-top .cont li a {
  color: #fff;
  display: block;
}
.nav .nav-top .cont li a:hover {
  color: #7999fd;
}
.nav .nav-top .active a {
  color: #7999fd;
}
.nav .nav-top i {
  position: absolute;
  width: 100px;
  height: 3px;
  background: #7999fd;
  bottom: 0;
  margin-left: 360px;
}
.nav .nav-move {
  display: none;
}
.nav .nav-more {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 29;
  padding: 20px 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  font-size: 12px;
}
.nav .nav-more .cont {
  padding: 80px 0 0 221px;
}
.nav .nav-more .cont a {
  color: #e0e0e0;
}
.nav .nav-more .cont a i {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 6px;
}
.nav .nav-more .cont a i.hot,
.nav .nav-more .cont a i.new,
.nav .nav-more .cont a i.test {
  background: url(/images/sprite_img.png) -42px -94px no-repeat;
}
.nav .nav-more .cont a i.new {
  background-position: -57px -94px;
}
.nav .nav-more .cont a i.test {
  background-position: -88px -94px;
}
.nav .nav-more .cont ul li {
  height: 38px;
  line-height: 38px;
  text-align: center;
}
.nav .nav-more .cont ul li a:hover {
  color: #ffffff;
}
.nav .nav-more .cont .ul_com {
  float: left;
  width: 100px;
  margin-right: 20px;
  padding-top: 1px;
  padding-bottom: 26px;
}
.nav .nav-more .cont .ul_com_game {
  width: auto;
  margin-left: 14px;
}
.nav .nav-more .cont .ul_com_game ul {
  float: left;
  width: auto;
  margin-right: 16px;
}
.nav .nav-more .cont .ul_com_game ul li {
  text-align: left;
}
.hide-list {
  position: absolute;
  z-index: 0;
  width: 142px;
  padding: 80px 0 5px;
  margin-left: -20px;
  margin-top: -80px;
  background: rgba(0, 0, 0, 0.4);
  z-index: -1;
  margin-top: -100px;
  opacity: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.hide-list a {
  height: 46px;
  line-height: 46px;
}
.nav .nav-top .cont .contact:hover .hide-list {
  margin-top: -80px;
  opacity: 1;
  z-index: -1;
}
.pages {
  width: 1200px;
  margin: 70px auto;
  text-align: center;
}
.pages a {
  color: #b6b6b6;
  margin-right: 7px;
  padding: 0 12px;
  height: 28px;
  line-height: 28px;
  background: #ffffff;
  font-size: 14px;
  display: inline-block;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}
.pages a:hover {
  border-color: #8ed872;
  color: #8ed872;
}
.footer_wrap {
  position: relative;
  bottom: 0;
  width: 100%;
  min-width: 1200px;
  height: 140px;
  padding-top: 32px;
  background: #000;
  z-index: 9998;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.footer_wrap .footer_box {
  width: 1200px;
  margin: 0 auto;
}
.footer_wrap .footer_box dl {
  display: block;
  width: 930px;
  margin: 0 auto;
}
.footer_wrap .footer_box dl dt,
.footer_wrap .footer_box dl img {
  float: left;
}
.footer_wrap .footer_box dl dt {
  margin-top: 34px;
}
.footer_wrap .footer_box dl dd {
  float: right;
  width: 740px;
  color: #fff;
  font-size: 12px;
  text-align: left;
}
.footer_wrap .footer_box dl dd p {
  overflow: hidden;
  bottom: 0;
  height: 25px;
  line-height: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.footer_wrap .footer_box dl dd p a {
  margin: 0px 7px;
  color: #fff;
}
.footer_wrap .footer_box dl dd p a:hover {
  color: #fff;
  cursor: pointer;
}
.footer_wrap .cooperation {
  float: left;
  width: 210px;
  height: 65px;
  padding-top: 34px;
  margin: -9px 0 0 46px;
}
.footer_wrap .cooperation .coop-box {
  position: relative;
  width: 172px;
  height: 29px;
  padding-top: 3px;
  background: #b5b5b5;
  color: #333;
  font-size: 12px;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}
.footer_wrap .cooperation .coop-box span {
  float: left;
  width: 127px;
  border-right: 1px solid #8e8e8e;
  line-height: 27px;
  text-align: center;
  padding-right: 10px;
}
.footer_wrap .cooperation .coop-box img {
  width: 12px;
  height: 8px;
  margin: 10px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.footer_wrap .cooperation .coop-box:hover {
  background: #fff;
  cursor: pointer;
}
.index {
  width: 100%;
}
.index .banner_wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 974px;
}
.index .banner_wrap .banner1_btn {
  position: absolute;
  bottom: 12vh;
  left: 50%;
  width: 1200px;
  height: 213px;
  margin-left: -600px;
}
.index .banner_wrap .banner1_btn ul {
  position: absolute;
  z-index: 2;
  left: 0%;
  width: 1200px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.index .banner_wrap .banner1_btn ul li {
  float: left;
  width: 118px;
  height: 213px;
  margin: 0 3px;
  position: relative;
  cursor: pointer;
}
.index .banner_wrap .banner1_btn ul img {
  width: 120px;
  height: 120px;
  display: block;
  border-radius: 20px;
  -webkit-filter: brightness(0.5);
}
.index .banner_wrap .banner1_btn ul span {
  display: block;
  width: 120%;
  height: 47px;
  margin-left: -10%;
  color: #fff;
  font-size: 12px;
  line-height: 47px;
  text-align: center;
}
.index .banner_wrap .banner1_btn ul .active img {
  -webkit-filter: brightness(1);
}
.index .banner_wrap .banner1 {
  height: 974px;
}
.index .banner_wrap .banner1 .bg {
  position: relative;
  width: 100%;
  height: 974px;
}
.index .banner_wrap .banner1 .bg img {
  display: block;
}
.index .banner_wrap .banner1 .bg .pc_img {
  display: block;
}
.index .banner_wrap .banner1 .bg .move_img {
  display: none;
}
.index .banner_wrap .banner1 .bg:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1000px;
  background: url("/images/linear.png") no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -ms-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  content: '';
}
.index .banner_wrap .banner1 .move {
  position: absolute;
  top: 218px;
  left: 0;
  right: 0;
  width: 1200px;
  margin: 0 auto;
  color: #f4f5f7;
}
.index .banner_wrap .banner1 .move .logo {
  height: 120px;
  margin-left: -10px;
}
.index .banner_wrap .banner1 .move img {
  display: block;
}
.index .banner_wrap .banner1 .move i,
.index .banner_wrap .banner1 .move a {
  display: block;
  color: #f4f5f7;
}
.index .banner_wrap .banner1 .move a.btn {
  width: 160px;
  height: 42px;
  background: #7999fd;
  border-radius: 20px;
  position: relative;
  line-height: 42px;
  text-align: center;
}
.index .banner_wrap .banner1 .move a.btn:hover {
  opacity: 0.8;
}
.index .banner_wrap .banner1 .move a.btn:after {
  content: '';
  background: url(/images/sprite_img.png) -99px -100px no-repeat;
  width: 9px;
  height: 15px;
  position: absolute;
  right: 36px;
  top: 14px;
}
.index .banner_wrap .banner1 .move h3 {
  padding: 0px 0 15px;
  font-size: 36px;
  font-weight: bold;
  line-height: 80px;
  text-shadow: 2px 2px 5px #000;
}
.index .conter_wrap {
  width: 1200px;
  margin: 0 auto;
  padding-top: 24px;
  overflow: auto;
  zoom: 1;
  padding-bottom: 85px;
}
.index .conter_wrap .conter_word_list {
  float: left;
  display: inline;
}
.index .conter_wrap .conter_word_list strong {
  display: block;
  color: #010101;
  border-bottom: 1px solid #c5c5c5;
  height: 52px;
  line-height: 52px;
  font-size: 16px;
  margin-bottom: 19px;
}
.index .conter_wrap .conter_word_list a {
  display: block;
  line-height: 34px;
  color: #333333;
  font-size: 14px;
}
.index .conter_wrap .conter_word_list a span {
  float: right;
  color: #999;
  font-size: 12px;
}
.index .conter_wrap .conter_word_list a p {
  width: 270px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}
.index .conter_wrap .conter_word_list a:hover {
  color: #7999fd;
}
.index .conter_wrap .conter_word_list a::after {
  content: "";
  display: block;
  clear: both;
}
.index .conter_wrap .list_1 {
  width: 200px;
}
.index .conter_wrap .list_1 a {
  float: left;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
}
.index .conter_wrap .list_1 a i {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 6px;
}
.index .conter_wrap .list_1 a .hot {
  background: url(/images/sprite_img.png) -42px -94px no-repeat;
}
.index .conter_wrap .list_1 a .new {
  background: url(/images/sprite_img.png) -57px -94px no-repeat;
}
.index .conter_wrap .list_1 a .test {
  background: url(/images/sprite_img.png) -88px -94px no-repeat;
}
.index .conter_wrap .list_2 {
  width: 374px;
  margin-left: 70px;
}
.index .conter_wrap .list_3 {
  width: 202px;
  margin-left: 75px;
}
.index .conter_wrap .list_4 {
  width: 200px;
  margin-left: 75px;
}
.index .mobile_conter_wrap {
  display: none;
}
.footer_wrap .footer_box_mobile {
  display: none;
}
.about_us {
  width: 100%;
  height: auto;
}
.about_us .about_main {
  width: 100%;
  padding-top: 79px;
}
.about_us .about_main .title {
  display: none;
}
.about_us .about_main .section {
  width: 100%;
  height: auto;
}
.about_us .about_main .section,
.about_us .about_main .section p {
  width: 100%;
}
.about_us .about_main .section_01 p {
  height: 700px;
  background: url("/images/material/about_bg_01.jpg") center no-repeat;
}
.about_us .about_main .section_02 p {
  height: 729px;
  background: url("/images/material/about_bg_02.jpg") center no-repeat;
}
.about_us .about_main .section_02_02 p {
  height: 873px;
  background: url("/images/material/about_bg_02_02.jpg") center no-repeat;
}
.about_us .about_main .section_02_03 p {
  height: 720px;
  background: url("/images/material/about_bg_02_03.jpg") center no-repeat;
}
.about_us .about_main .section_03 p {
  height: 742px;
  background: url("/images/material/about_bg_03.jpg") center no-repeat;
}
.about_us .about_main .section_04 p {
  height: 720px;
  background: url("/images/material/about_bg_04.jpg") center no-repeat;
}
.about_us .about_main .section_05 p {
  height: 742px;
  background: url("/images/material/about_bg_05.jpg") center no-repeat;
}
.about_us .about_main .section_06 p {
  height: 640px;
  background: url("/images/material/about_bg_06.jpg") center no-repeat;
}
.cooperation {
  width: 100%;
  height: auto;
}
.cooperation .banner {
  width: 100%;
  height: 450px;
  background: url("/images/material/banner_cooperation.jpg") center no-repeat;
}
.cooperation .banner h1 {
  width: 100%;
  padding: 175px 0 10px;
  color: #fff;
  font-size: 80px;
  font-weight: bold;
  text-align: center;
}
.cooperation .banner p {
  color: #fff;
  font-size: 36px;
  text-align: center;
}
.cooperation .coop_main {
  width: 100%;
}
.cooperation .coop_main .contact_inf {
  width: 100%;
}
.cooperation .coop_main .contact_inf dl {
  width: 796px;
  height: 490px;
  margin: 0 auto;
  padding-top: 60px;
}
.cooperation .coop_main .contact_inf dt {
  float: right;
  width: 154px;
  height: 154px;
}
.cooperation .coop_main .contact_inf dt img {
  display: block;
  width: 100%;
  height: 100%;
}
.cooperation .coop_main .contact_inf dd {
  float: left;
  width: 600px;
}
.cooperation .coop_main .contact_inf dd p {
  margin-bottom: 14px;
  color: #333;
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.news {
  width: 100%;
  height: auto;
}
.news .banner {
  width: 100%;
  height: 450px;
  background: url("/images/material/banner_news.jpg") center no-repeat;
}
.news .banner h1 {
  width: 100%;
  padding: 175px 0 10px;
  color: #fff;
  font-size: 80px;
  font-weight: bold;
  text-align: center;
}
.news .banner p {
  color: #fff;
  font-size: 36px;
  text-align: center;
}
.news .news_main {
  width: 100%;
  padding: 60px 0 0;
}
.news .news_main .news_list {
  width: 1200px;
  margin: 0 auto;
}
.news .news_main .news_list .news_li {
  float: left;
  width: 274px;
  height: 300px;
  margin: 0 12px 50px;
  box-shadow: 0 2px 10px 0 #dee4e8;
}
.news .news_main .news_list .news_li a {
  position: relative;
  display: block;
  width: 100%;
  height: 300px;
  border-radius: 5px;
}
.news .news_main .news_list .news_li .img {
  overflow: hidden;
  display: block;
  width: 274px;
  height: 182px;
}
.news .news_main .news_list .news_li .img img {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.news .news_main .news_list .news_li .txt {
  padding: 0 20px;
}
.news .news_main .news_list .news_li .txt h3 {
  overflow: hidden;
  height: 50px;
  padding: 8px 0 4px;
  color: #000;
  line-height: 26px;
  font-weight: bold;
  font-size: 18px;
}
.news .news_main .news_list .news_li .txt p {
  font-size: 14px;
  color: #aaa;
  line-height: 20px;
  height: 40px;
  overflow: hidden;
}
.news .news_main .news_list .news_li a:after {
  content: '';
  position: absolute;
  bottom: -39px;
  left: 50%;
  margin-left: -56px;
  width: 118px;
  height: 31px;
  background: url(/images/sprite_img.png) 0 -62px no-repeat;
  opacity: 0;
  transition: .3s all;
  -webkit-transition: .3s all;
}
.news .news_main .news_list .news_li a:hover {
  box-shadow: 0 2px 10px 0 #dee4e8;
  border-radius: 3px;
}
.news .news_main .news_list .news_li a:hover:after {
  bottom: -17px;
  opacity: 1;
}
.news .news_main .news_list .news_li a:hover img {
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  transform: scale(1.4);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.news .news_main_inside {
  width: 100%;
  min-height: 750px;
  padding: 60px 0;
}
.news .news_main_inside .news_conter {
  width: 1200px;
  height: auto;
  margin: 0 auto;
}
.news .news_main_inside .news_conter .title {
  width: 90%;
  padding-bottom: 60px;
  margin: 0 auto;
  text-align: center;
}
.news .news_main_inside .news_conter .title h1 {
  margin-bottom: 20px;
  color: #000;
  font-size: 30px;
}
.news .news_main_inside .news_conter .title span {
  position: relative;
  width: 200px;
  color: #ccc;
  font-size: 14px;
}
.news .news_main_inside .news_conter .title span:after {
  position: absolute;
  top: 1px;
  left: -20px;
  width: 15px;
  height: 16px;
  background: url("/images/sprite_img.png") -47px -110px no-repeat;
  content: '';
}
.news .news_main_inside .news_conter .conter {
  width: 90%;
  margin: 0 auto;
}
.news .news_main_inside .news_conter .conter p {
  color: #333;
  line-height: 26px;
  font-size: 16px;
  text-indent: 30px;
  text-align: justify;
}
.news .news_main_inside .news_conter .conter h2 {
  padding: 10px 0;
  color: #333;
  font-size: 22px;
}
.news .news_main_inside .news_conter .conter img {
  display: block;
  width: 60%;
  height: auto;
  margin: 0 auto;
  padding: 30px 0;
}
.news .news_main_inside .news_conter .conter .s-33 {
  width: 33%;
}
.games {
  width: 100%;
  height: auto;
}
.games .banner {
  width: 100%;
  height: 450px;
  background: url("/images/material/banner_games.jpg") center no-repeat;
}
.games .banner h1 {
  width: 100%;
  padding: 175px 0 10px;
  color: #fff;
  font-size: 80px;
  font-weight: bold;
  text-align: center;
}
.games .banner p {
  color: #fff;
  font-size: 36px;
  text-align: center;
}
.games .games_main {
  width: 100%;
}
.games .games_main .games_box {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  padding: 60px 0 160px;
}
.games .games_main .games_box .games_a_box {
  overflow: hidden;
  position: relative;
  float: left;
  width: 390px;
  height: 410px;
  margin: 0 5px 10px;
  cursor: pointer;
}
.games .games_main .games_box .games_a_box .games_hide_box {
  position: absolute;
  top: 0;
  left: -390px;
  width: 390px;
  height: 410px;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.games .games_main .games_box .games_a_box .games_hide_box h4 {
  display: block;
  width: 100%;
  height: auto;
  padding: 290px 0 10px;
  color: #fff;
  font-size: 20px;
  text-align: center;
}
.games .games_main .games_box .games_a_box .games_hide_box a {
  display: block;
  width: 112px;
  height: 30px;
  margin: 0 auto;
  background: url("/images/sprite_img.png") 0 -31px no-repeat;
}
.games .games_main .games_box .games_a_box .games_hide_box a:hover {
  background: url("/images/sprite_img.png") no-repeat;
}
.games .games_main .games_box .games_a_box:hover .games_hide_box {
  left: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
/* 屏幕分辨率放大为 125 */
@media (-webkit-min-device-pixel-ratio: 1.25) {
  .about_us .about_main .section_02 p {
    height: 650px;
    background: url("/images/material/about_bg_02.jpg") center no-repeat;
    background-size: cover;
  }
}
/* 屏幕分辨率放大为 150 */
@media (-webkit-min-device-pixel-ratio: 1.5) {
  .about_us .about_main .section_02 p {
    height: 550px;
    background: url("/images/material/about_bg_02.jpg") center no-repeat;
    background-size: cover;
  }
}
@media screen and (max-width: 1440px) {
  .index .banner_wrap {
    height: 740px;
  }
  .index .banner_wrap .banner1 {
    height: 740px;
  }
  .index .banner_wrap .banner1 .move {
    top: 120px;
    width: 1000px;
  }
  .index .banner_wrap .swiper-container-fade .swiper-slide .bg {
    width: 1500px;
    height: 850px;
    margin: 0 auto;
  }
  .index .banner_wrap .swiper-container-fade .swiper-slide .bg img {
    margin-top: -120px;
  }
  .index .banner_wrap .banner1 .bg .pc_img {
    margin-left: -190px;
    -webkit-transform: scale(0.78);
    transform: scale(0.78);
  }
  .index .banner_wrap .banner1_btn {
    bottom: 8vh;
  }
}
@media screen and (max-width: 1280px) {
  .index .footer_wrap {
    width: 100%;
  }
  .index .footer_wrap .footer_box {
    width: 1100px;
  }
}
@media screen and (max-width: 1024px) {
  .index .footer_wrap {
    width: 100%;
  }
  .index .footer_wrap .footer_box {
    width: 900px;
  }
}
@media screen and (max-width: 750px) {
  .index {
    min-width: 320px;
    max-width: 750px;
  }
  .nav-more {
    display: none;
  }
  .index .banner_wrap {
    height: 5.7rem;
  }
  .index .banner_wrap .banner1 {
    overflow: hidden;
    height: 5.7rem;
  }
  .index .banner_wrap .banner1 .centering {
    width: auto;
    margin: 0;
    left: 0;
  }
  .index .banner_wrap .banner1 .swiper-slide .bg {
    position: relative;
    width: 100vw;
    height: 5.7rem;
    margin: 0;
  }
  .index .banner_wrap .banner1 .swiper-slide .bg img {
    display: block;
    width: 100%;
    margin: 0;
  }
  .index .banner_wrap .banner1 .swiper-slide .bg .pc_img {
    display: none;
  }
  .index .banner_wrap .banner1 .swiper-slide .bg .move_img {
    display: block;
  }
  .index .banner_wrap .banner1 .swiper-slide .bg:after {
    position: absolute;
    bottom: -4rem;
  }
  .index .banner_wrap .banner1_btn {
    width: auto;
    left: 0%;
    top: 5rem;
    height: 30px;
    margin-left: 0;
  }
  .index .banner_wrap .banner1_btn ul {
    margin-left: 0;
    width: 100vw;
  }
  .index .banner_wrap .banner1_btn ul li {
    width: 0.18rem;
    height: 0.18rem;
    margin: 0 5px;
    border-radius: 50%;
    background: #fff;
  }
  .index .banner_wrap .banner1_btn ul li a {
    display: none;
  }
  .index .banner_wrap .banner1_btn ul li a:after {
    display: none;
  }
  .index .banner_wrap .banner1_btn ul .active {
    background: red;
  }
  .index .banner_wrap .banner1_btn ul .active:after {
    display: none;
  }
  .footer_wrap {
    overflow: hidden;
    width: 100%;
    min-width: 100%;
    height: auto;
  }
  .footer_wrap .footer_box {
    display: none;
  }
  .footer_wrap .footer_box_mobile {
    display: block;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  .footer_wrap .footer_box_mobile p {
    line-height: 26px;
    color: #929292;
    font-size: 12px;
    text-align: center;
  }
  .footer_wrap .footer_box_mobile p a {
    color: #929292;
    font-size: 12px;
  }
  .nav {
    max-width: 750px;
    min-width: 320px;
  }
  .nav .nav-top {
    display: none;
  }
  .nav .nav-move {
    position: relative;
    top: 0;
    display: block;
    width: 100%;
    height: 1.1rem;
    background: #000;
    z-index: 9981;
  }
  .nav .nav-move .mo_nav_btn {
    width: 0.77rem;
    height: 1.1rem;
    background: url(/images/bg_mo_nav_btn.jpg) no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
  }
  .nav .nav-move .logo {
    width: 12%;
    height: 0.75rem;
    margin: 0 auto;
    padding-top: 0.2rem;
  }
  .nav .nav-move .logo img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .nav .nav-move .nav_btn {
    width: 1rem;
    height: 1.1rem;
    background: url(/images/nav_btn.jpg) no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0;
  }
  .nav .nav-move .mo_nav_btn {
    width: 0.77rem;
    height: 1.1rem;
    background: url(/images/bg_mo_nav_btn.jpg) no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
  }
  .nav .nav-move .mo_nav_btn_on {
    width: 0.77rem;
    height: 1.1rem;
    background: url(/images/bg_mo_nav_btn_no.jpg) no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
  }
  .nav .nav-move .pop_nav_warp {
    position: absolute;
    z-index: 125;
    top: 1.1rem;
    width: 100%;
    border-top: 1px solid #12a7eb;
    width: 6.4rem;
    left: -5.4rem;
  }
  .nav .nav-move .pop_nav {
    display: none;
  }
  .nav .nav-move .pop_nav_list {
    width: 100%;
    position: absolute;
    z-index: 12;
    background: #111;
  }
  .nav .nav-move .pop_nav_list b {
    display: block;
    border-left: 3px solid #12a7eb;
    height: 0.3rem;
    font-size: 0.32rem;
    color: #ececec;
    font-weight: normal;
    line-height: 0.24rem;
    margin: 0.4rem 0 0 0.25rem;
    padding-left: 0.3rem;
  }
  .nav .nav-move .pop_nav_ul {
    width: 100%;
  }
  .nav .nav-move .pop_nav_ul li {
    margin: 0.25rem 0.3rem 0.25rem 0.3rem;
    overflow: auto;
    padding: 0.2rem 0;
    border-bottom: 1px solid #252433;
  }
  .nav .nav-move .pop_nav_ul li a {
    color: #ececec;
  }
  .nav .nav-move .pop_nav_ul li a:hover {
    color: #f6d191;
  }
  .nav .nav-move .pop_nav_ul li img {
    float: left;
    width: 1.2rem;
  }
  .nav .nav-move .pop_nav_ul li span {
    display: inline-block;
    float: left;
    margin-left: 0.3rem;
    margin-top: 0.1rem;
  }
  .nav .nav-move .pop_nav_ul li span strong {
    font-size: 0.3rem;
    font-weight: normal;
    display: block;
  }
  .nav .nav-move .pop_nav_ul li span em {
    font-size: 0.2rem;
    display: block;
    margin-top: 0.15rem;
  }
  .nav .nav-move .pop_nav_ul li:nth-last-child(1) {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .nav .nav-move .pop_nav_ul .pop_nav_list_mid {
    padding: 0.3rem 0;
  }
  .nav .nav-move .hide {
    display: none;
  }
  .nav .nav-move .mo_nav_btn_on {
    position: absolute;
    left: 0;
    top: 0;
    width: 0.77rem;
    height: 1.1rem;
    background: url(/images/bg_mo_nav_btn_no.jpg) no-repeat;
    background-size: cover;
  }
  .nav_list {
    display: none;
    width: 2rem;
    border-top: 1px solid #252433;
    background: #111111;
  }
  .nav_list a {
    display: block;
    color: #fff;
    font-size: 0.28rem;
    line-height: 1rem;
    text-align: center;
    border-bottom: 1px solid #252433;
  }
  .nav_list .a_on {
    color: #15b5ee;
  }
  .center {
    width: auto;
  }
  .index .conter_wrap {
    display: none;
  }
  .index .mobile_conter_wrap {
    display: block;
    padding-top: 20px;
    background: #252433;
  }
  .index .mobile_conter_wrap a {
    position: relative;
    height: 0.8rem;
    line-height: 0.8rem;
    color: #fff;
    font-size: 0.28rem;
    display: block;
    margin: 0 0.2rem 0 0.2rem;
    border-bottom: 1px solid #4c4c58;
    padding: 0.2rem;
  }
  .index .mobile_conter_wrap a:after {
    position: absolute;
    top: 0.5rem;
    right: 3%;
    width: 10px;
    height: 21px;
    background: url(/images/icon_arr_left.png) no-repeat;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -ms-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    content: '';
  }
  .index .mobile_conter_wrap a:nth-last-child(1) {
    border-bottom: none;
  }
  .index .banner_wrap .banner1 .move {
    top: 1.5rem;
    left: 50vw;
    right: auto;
    margin-left: -2.5rem;
    width: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .index .banner_wrap .banner1 .move a.btn {
    display: none;
  }
  .index .banner_wrap .banner1 .move .logo {
    height: auto;
    width: 100%;
    margin-bottom: .15rem;
    display: flex;
    justify-content: center;
  }
  .index .banner_wrap .banner1 .move .logo img {
    width: 4rem;
  }
  .index .banner_wrap .banner1 .move .renxin,
  .index .banner_wrap .banner1 .move .renxin2 {
    width: 2rem;
  }
  .index .banner_wrap .banner1 .move h3 {
    line-height: auto;
    font-size: 0.24rem;
    line-height: 0.4rem;
    font-weight: bold;
    text-shadow: 0 0 20px #000;
  }
  .about_us .about_main {
    padding-top: 0;
  }
  .about_us .about_main .section {
    height: 3rem;
  }
  .about_us .about_main .section p {
    height: 3rem;
    background-size: cover;
  }
  .about_us .about_main .title {
    display: block;
    font-size: 0.36rem;
    color: #111111;
    font-weight: normal;
    padding: 0.25rem 0;
    text-align: center;
  }
  .cooperation .banner {
    width: 100%;
    height: 5rem;
    background-size: cover;
  }
  .cooperation .banner h1 {
    padding: 1.25rem 0 0.5rem;
    font-size: 0.6rem;
  }
  .cooperation .banner p {
    font-size: 0.5rem;
  }
  .cooperation .coop_main .contact_inf dl {
    width: 100%;
  }
  .cooperation .coop_main .contact_inf dd {
    float: none;
    display: block;
    width: 70%;
    margin: 0 auto 1rem;
  }
  .cooperation .coop_main .contact_inf dd p {
    font-size: 0.3rem;
    overflow: visible;
    white-space: normal;
    text-align: center;
  }
  .cooperation .coop_main .contact_inf dt {
    float: none;
    display: block;
    margin: 0 auto;
  }
  .news .banner {
    width: 100%;
    height: 5rem;
    background-size: cover;
  }
  .news .banner h1 {
    padding: 1.25rem 0 0.5rem;
    font-size: 0.6rem;
  }
  .news .banner p {
    font-size: 0.5rem;
  }
  .news .news_main .news_list {
    width: 100%;
  }
  .news .news_main .news_list .news_li {
    float: none;
    display: block;
    margin: 0 auto 50px;
  }
  .news .news_main .news_list .news_li a:after {
    bottom: -17px;
    opacity: 1;
  }
  .news .news_main .pages {
    display: none;
  }
  .news .news_main_inside .news_conter {
    width: 100%;
  }
  .news .news_main_inside .news_conter .title h1 {
    font-size: 0.4rem;
  }
  .news .news_main_inside .news_conter .conter p {
    font-size: 0.25rem;
  }
  .news .news_main_inside .news_conter .conter img {
    width: 100%;
  }
  .news .news_main_inside .news_conter .conter .s-33 {
    width: 75%;
  }
  .news .news_main_inside .news_conter .conter h2 {
    font-size: 0.3rem;
  }
  .games .banner {
    width: 100%;
    height: 5rem;
    background-size: cover;
  }
  .games .banner h1 {
    padding: 1.25rem 0 0.5rem;
    font-size: 0.6rem;
  }
  .games .banner p {
    width: 85%;
    margin: 0 auto;
    font-size: 0.5rem;
  }
  .games .games_main .games_box {
    width: 100%;
    padding: 60px 0;
  }
  .games .games_main .games_box .games_a_box {
    float: none;
    display: block;
    width: 5rem;
    height: 5rem;
    margin: 0 auto 0.5rem;
    background-size: cover;
  }
  .games .games_main .games_box .games_a_box .games_bg img {
    display: block;
    width: 100%;
  }
  .games .games_main .games_box .games_a_box .games_hide_box {
    left: 0;
    width: 5rem;
    height: 5rem;
    background: rgba(138, 218, 246, 0.35);
  }
  .games .games_main .games_box .games_a_box .games_hide_box h4 {
    padding-top: 3rem;
  }
  .games .games_main .games_box .games_a_box .games_hide_box a {
    background: url("/images/sprite_img.png") 0 0px no-repeat;
  }
}
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #1E2C2F;
}
@media only screen and (min-width: 1000px) and (max-width: 1280px) {
  .index .banner_wrap .banner1 .move {
    width: 870px;
  }
  .index .banner_wrap .banner1_btn {
    width: 1000px;
    margin-left: -500px;
  }
  .index .banner_wrap .banner1_btn ul {
    width: 1000px;
  }
  .index .banner_wrap .banner1_btn ul li {
    width: 90px;
  }
  .index .banner_wrap .banner1_btn ul li a {
    width: 135px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .index .banner_wrap .banner1_btn ul li a img {
    width: 100px;
    height: 100px;
  }
  .index .banner_wrap .banner1_btn ul li a span {
    width: 135px;
    margin-left: 0;
    line-height: 32px;
    transform: scale(0.88);
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1050px) {
  .index .banner_wrap .banner1_btn ul li a img {
    width: 100px;
    height: 100px;
  }
}
@media only screen and (min-width: 750px) and (max-width: 999px) {
  .index .banner_wrap .banner1 .move {
    width: 700px;
  }
  .index .banner_wrap .banner1 .move h3 {
    font-size: 30px;
    line-height: 60px;
  }
  .index .banner_wrap .banner1_btn {
    width: 750px;
    margin-left: -375px;
  }
  .index .banner_wrap .banner1_btn ul {
    width: 750px;
  }
  .index .banner_wrap .banner1_btn ul li {
    width: 90px;
    margin: 0 6px;
  }
  .index .banner_wrap .banner1_btn ul li a {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .index .banner_wrap .banner1_btn ul li a img {
    width: 90px;
    height: 90px;
  }
  .index .banner_wrap .banner1_btn ul li a span {
    width: 100px;
    margin-left: 0;
    line-height: 20px;
    transform: scale(0.88);
  }
}
.intro-warp {
  width: 100%;
  height: auto;
  background: url("/images/intro-bg.png") center center no-repeat;
  background-size: cover;
  margin: 0;
  overflow: hidden;
  padding: 70px 0 10px;
}
.intro-warp > div {
  width: 80%;
  margin: 0 auto;
}
.intro-warp > div div {
  text-align: center;
  padding-top: 80px;
}
.intro-warp > div h3 {
  margin-top: 30px;
  font-size: 36px;
  color: #fff;
  text-align: center;
}
.intro-warp > div h4 {
  font-size: 18px;
  color: #fff;
  text-align: center;
}
.intro-warp > div p {
  margin-top: 25px;
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.intro-warp > div .slash {
  display: none;
}
.intro-warp > div .game_icons {
  max-height: 300px;
  overflow: hidden;
  padding-top: 50px;
}
.intro-warp > div .game_icons img {
  width: 100%;
}
